import { EVENT_UPDATE } from 'javascripts/react/utils/constants/issues/createForms';

class RelationFieldsInterface {
  constructor(widgetWrapperSelector) {
    this.fieldsBlock = document.querySelector(widgetWrapperSelector);
  }

  get isPresent() {
    return !!this.fieldsBlock;
  }

  get selectedValues() {
    if (!this.isPresent) { return {}; }

    const companyField = this.fieldsBlock.querySelector('[data-role="issue-company-id-input"]');
    const contactField = this.fieldsBlock.querySelector('[data-role="issue-contact-id-input"]');
    const agreementField = this.fieldsBlock.querySelector('[data-role="issue-agreement-id-input"]');
    const maintenanceEntityField = this.fieldsBlock
      .querySelector('[data-role="issue-maintenance-entity-id-input"]');
    const equipmentFields = this.fieldsBlock.querySelectorAll('[data-role="issue-equipment-id-input"]');

    return {
      company_id: companyField && companyField.value,
      contact_id: contactField && contactField.value,
      agreement_id: agreementField && agreementField.value,
      maintenance_entity_id: maintenanceEntityField && maintenanceEntityField.value,
      equipment_ids: Array.from(equipmentFields).map((field) => field.value),
    };
  }

  update(data) {
    if (!this.isPresent) { return; }

    // eslint-disable-next-line no-undef
    const event = new CustomEvent(EVENT_UPDATE, { detail: data });

    this.fieldsBlock.dispatchEvent(event);
  }
}

export default RelationFieldsInterface;

// Codes
export const CLIENT_FIELD_CODE = 'client';
export const AGREEMENT_FIELD_CODE = 'agreement_id';
export const MAINTENANCE_ENTITY_FIELD_CODE = 'maintenance_entity_id';
export const EQUIPMENT_FIELD_CODE = 'equipment_ids';

// Events
export const UPDATE_TYPE_EVENT = 'issue_form_work_type:update';
export const RESOLVE_TYPE_EVENT = 'issue_form_work_type:resolve';

export const UPDATE_PRIORITY_EVENT = 'issue_form_priority:update';
export const RESOLVE_PRIORITY_EVENT = 'issue_form_priority:resolve';

export const EVENT_UPDATE = 'fields:update';
export const EVENT_RESOLVE = 'fields:resolve';

export const TOGGLE_MASS_CREATION_EVENT = 'mass_creation:toggle';

// Fields
export const ISSUE_CREATE_FORM_COEXECUTORS = 'issue-create-form-coexecutors';
export const ISSUE_CREATE_FORM_EMPLOYEE_OBSERVERS_FIELD = 'issue-create-form-employee-observers-field';
export const ISSUE_CREATE_FORM_EMPLOYEE_OBSERVERS_INPUT = 'issue-employee-observer-ids-input';

export const RELATED_FIELDS_WRAPPER_SELECTOR = '[data-role="issue-create-form-relation-fields"]';

import { UPDATE_PRIORITY_EVENT, RESOLVE_PRIORITY_EVENT }
  from 'javascripts/react/utils/constants/issues/createForms';

const ISSUE_CREATE_FORM_PRIORITY_FIELD = 'issue-create-form-priority-field';
export const ISSUE_CREATE_FORM_PRIORITY_INPUT = 'issue-priority-id-input';


class PriorityFieldInterface {
  constructor() {
    this.selectedPriority = null;
    this.priorityBlock = document
      .querySelector(`[data-role="${ISSUE_CREATE_FORM_PRIORITY_FIELD}"]`);
  }

  get isPresent() {
    return Boolean(this.priorityBlock);
  }

  changedManually() {
    return $(this.priorityBlock).data('changed-manually');
  }

  selectedPriorityId() {
    if (this.selectedPriority) { return this.selectedPriority.value; }
    if (!this.isPresent) { return null; }

    const priorityField = this.priorityBlock.querySelector(
      `[data-role="${ISSUE_CREATE_FORM_PRIORITY_INPUT}"]`
    );

    return priorityField && priorityField.value;
  }

  subscribeToUpdates(callback) {
    if (!this.isPresent) { return; }

    $(this.priorityBlock).on(RESOLVE_PRIORITY_EVENT, ({ detail }) => {
      $(this.priorityBlock).data('changed-manually', true);
      this.selectedPriority = detail;
      callback(detail);
    });
  }

  update(data) {
    if (!this.isPresent) { return; }

    this.selectedPriority = { value: data.value, name: data.name };
    // eslint-disable-next-line no-undef
    const event = new CustomEvent(UPDATE_PRIORITY_EVENT, { detail: data });

    this.priorityBlock.dispatchEvent(event);
  }
}

export default PriorityFieldInterface;

`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import StringUtils from 'javascripts/vendors/string_utils'`
`import escape_html from 'javascripts/components/shared/escape_html'`
`import throttled from 'javascripts/components/shared/throttled'`
`import Mark from 'mark.js'`
`import hasSearchContent from 'javascripts/react/services/search_by_words_service'`

# contract 'simple interpolation'
MultilevelSelect = do ->

  init: (selector) ->
    elements = $(selector).not('[data-initialized="true"]')
    return false unless elements.length

    for element in elements
      @new $(element)

  new: (widget) ->
    selected = widget.find('.selected')
    @set_text_and_indicator_for(widget) unless widget.attr('selected_text')
    @set_selected_text_and_indicator_for(widget)
    widget.attr('page', 1)
    @initialize_events(widget)

  rebuild: (widget) ->
    widget.attr('data-initialized', false)
    widget.attr('page', 1)
    widget.attr('search','')
    widget.removeAttr('full-load')

    reset_btn = widget.closest('.input-with-reset').find('.reset a')
    if reset_btn.length > 0
      btn_disabled = true
      widget.find('input[data-role="selected_value"]').each (index, item) ->
        item_value = $(item).val()
        btn_disabled &&= item_value == null || item_value == ''
      reset_btn.toggleClass('disabled', btn_disabled)

  append_and_show_list_dropdown: (widget) ->
    initialized = widget.attr('data-initialized') == 'true'
    unless initialized
      if widget.attr('remote')
        @load_collection(widget)
      else
        @select_option_for(widget)
        @set_text_and_indicator_for(widget)
        @set_selected_text_and_indicator_for(widget)
        widget.attr('data-initialized', 'true')

    @show_dropdown(widget)
    @normalize_list_position(widget.find('.list')) if initialized

  show_dropdown: (widget) ->
    list   = widget.find('.list')
    unless widget.attr('simple_select')
      @set_min_width_for(widget)
    $('.multilevel-select--li.hovered').removeClass('hovered')

    if widget.attr('simple_select')
      list.fadeIn()
    else
      list.show()

    # set carriage at the end of Search Input
    input = list.find('input:visible')
    if input.length isnt 0
      len = input.val().length
      input.focus()[0].setSelectionRange(len, len)

    # Set inited state
    @no_results_visibility_for(widget)
    @set_list_height_for(widget)
    @setListPositionForPreview(widget)

  selected_values_for: (widget) ->
    selected = {}
    widget.find('@selected_value').each ->
      input = $(@)
      name = input.data('name')
      value = input.val()
      selected[name] = value

    selected

  load_collection: (widget) ->
    that = @
    setTimeout (-> that.show_spinner(widget)), 100
    page = parseInt(widget.attr('page')) || 1
    search = widget.attr('search')
    $.ajax
      url: widget.attr('collection_url'),
      data: {
        page: page,
        search: search,
        selected: @selected_values_for(widget)
      },
      datatype: 'json',
      success: (data) ->
        perform_json_response(data)

        that.select_option_for(widget)
        that.set_selected_text_and_indicator_for(widget)
        that.no_results_visibility_for(widget)
        that.set_list_height_for(widget)
        widget.attr('data-initialized', 'true')
        that.init_load_more(widget, data.is_last_page)
        setTimeout (-> that.hide_spinner(widget)), 100
        if widget.attr('search') && widget.attr('search') != ''
          that.filter_and_hightlight_elements_by_search_string(widget)

        that.normalize_list_position(widget.find('.list'))

        widget.removeAttr('loading-current-page')

  show_spinner: (widget) ->
    spinner_wrapper = widget.find('@multilevel-select-spinner')
    spinner = spinner_wrapper.find('.fa-spin')
    ul = widget.find('@multilevel-collection')
    spinner_wrapper.removeClass('hidden')

    spinner_height = spinner.height()
    spinner_width = spinner.width()
    wrapper_height = ul.outerHeight()
    wrapper_width = ul.outerWidth()
    height = if wrapper_height > 0 then wrapper_height else spinner_height
    width = if wrapper_width then wrapper_width else '100%'
    margin_left = (width - spinner_width) / 2
    margin_top = (height - spinner_height) / 2
    spinner_wrapper.css({ height: height, width: width })
    spinner.css({ 'margin-left': margin_left, 'margin-top': margin_top })

  hide_spinner: (widget) ->
    widget.find('@multilevel-select-spinner').addClass('hidden')

  load_more: (widget) ->
    return true if widget.attr('full-load')

    page = parseInt(widget.attr('page')) || 1
    widget.attr('page', page + 1)
    @load_collection(widget)

  init_load_more: (widget, is_last_page = true) ->
    widget.attr('full-load', true) if is_last_page

  filter_and_hightlight_elements_by_search_string: (widget) ->
    input = widget.find('.search input')
    elements  = widget.find('.list .multilevel-select--group')
    searchString = input.val().trim()
    inputText = StringUtils.escape_string(searchString)
    rexp      = RegExp(inputText, 'mig')
    replacer  = "<b>$&</b>"

    if inputText.length is 0
      elements.removeClass('hidden')
      widget.find('.multilevel-select--data').removeClass('hidden')
      widget.find('.multilevel-select--spoiler-btn').removeClass('closed')

      elements.each (index, elementGroup) =>
        elementGroup = $(elementGroup)
        elementLi = elementGroup.children('.multilevel-select--label').find('.multilevel-select--li')
        newText = elementLi.text().replace(rexp, replacer)
        elementLi.html(escape_html(newText))
    else
      elements.addClass('hidden')
      widget.find('.multilevel-select--data').addClass('hidden')
      widget.find('.multilevel-select--spoiler-btn').addClass('closed')

      elements.each (index, elementGroup) =>
        elementGroup = $(elementGroup)
        elementLi = elementGroup.children('.multilevel-select--label').find('.multilevel-select--li')

        header = elementLi.closest('.multilevel-select--ul > .multilevel-select--group').
          children('.multilevel-select--label')
          liText = elementLi.text()
          additionalSearchText = elementLi.data('additional-search-text')
          liText = "#{liText} #{additionalSearchText}" if additionalSearchText
        textForSearch = [header.text(), liText].join(' ')

        if hasSearchContent(searchString, textForSearch)
          @markNodeBySearchString(widget, $(header).get(0), searchString) if header.children(':has(mark)').length == 0
          @markNodeBySearchString(widget, $(elementLi).get(0), searchString)

          elementGroup.find('.multilevel-select--group').removeClass('hidden')
          elementGroup.show().removeClass('hidden')
          @show_parent_group_for(elementGroup)

    @set_list_height_for(widget)
    @no_results_visibility_for(widget)


  markNodeBySearchString: (widget, node, searchString) ->
    markInstance = new Mark(node)
    markOptions = { className: 'marked-text', ignoreJoiners: true, acrossElements: true }

    if widget.attr('remote')
      markInstance.mark(searchString, markOptions)
    else
      markInstance.unmark({ done: -> markInstance.mark(searchString, markOptions) })

  # EVENTS
  initialize_events: (widget) ->
    that = @

    # LOADING MORE COLLECTION ON SCROLLING DOWN
    widget.find('.multilevel-select--ul').off('scroll').on 'scroll', (e) ->
      return if widget.attr('loading-current-page')

      target = $(e.target)
      pixels_to_scroll = 50

      if (target.scrollTop() + target.height() + pixels_to_scroll) >= target.prop('scrollHeight')
        widget.attr('loading-current-page', true)
        that.load_more(widget)

    @inited ||= do =>
      doc = $ document

      # ON DOCUMENT
      doc.on 'click', (e) ->
        item           = $(e.target)
        msHolder       = item.closest('.multilevel-select')
        visibleHolders = $('.multilevel-select:has(.list:visible)')

        if msHolder.length is 0
          # item is not custom-selector child
          $('.multilevel-select .list').fadeOut(100)

        if visibleHolders.length > 0
          # item is custom-selector child
          # we will close all custom-selectors except this
          allLists = $('.multilevel-select .list:visible')
          thisList = msHolder.find('.list')
          allLists.not(thisList).hide()

      # SHOW LIST
      doc.on 'click', '.multilevel-select .selected', (e) =>
        item   = $ e.currentTarget
        widget = item.parents('.multilevel-select')

        @append_and_show_list_dropdown(widget)

      # ON OPTION CLICK
      doc.on 'click', '.multilevel-select .multilevel-select--li', (e) =>
        item = $ e.currentTarget
        @set_option_for(item)

      doc.on 'click', '.multilevel-select--spoiler-btn', (e) ->
        spoilerBtn     = $ e.target
        widget = spoilerBtn.closest('.multilevel-select')
        isClosedByDefault = widget.attr('closed_by_default')

        if isClosedByDefault
          spoilerContent = spoilerBtn.closest('.multilevel-select--group').find('.multilevel-select--data').first()
        else
          spoilerContent = spoilerBtn.closest('.multilevel-select--group').find('.multilevel-select--data')

        spoilerBtn.toggleClass('closed')
        spoilerContent.toggleClass('hidden')

        that.set_list_height_for(widget)

      # stop scrolling page if scrolling multilevel select
      doc.on 'mousewheel', '.multilevel-select--ul', (e, direction) =>
        scroll_inertia = 10
        selectbox_ul = $(e.currentTarget)
        height = selectbox_ul.height()
        scrollHeight = selectbox_ul.prop('scrollHeight') - scroll_inertia

        scrolling_bottom_maximum = (selectbox_ul.scrollTop() >= (scrollHeight - height)) && direction < 0
        scrolling_top_maximum = (selectbox_ul.scrollTop() == 0) && direction > 0

        e.preventDefault() if scrolling_bottom_maximum || scrolling_top_maximum

      doc.on 'mousewheel', '.multilevel-select-spinner', (e) =>
        e.preventDefault()

      # SEARCH
      doc.on 'keyup', '.multilevel-select .search input', (e) =>
        # do not reload collection if pressed button in KEYUP, KEYDOWN or ENTER
        return true if e.which in [13, 38, 40]

        input     = $ e.currentTarget
        widget    = input.closest('.multilevel-select')
        inputText = input.val().trim()

        throttled(->
          if widget.attr('remote')
            widget.removeAttr('full-load')
            if inputText.length < 2
              if widget.attr('search') && widget.attr('search') != ''
                widget.attr('search', '')
                widget.attr('page', 1)
                that.load_collection(widget)
            else
              widget.attr('search', inputText)
              widget.attr('page', 1)
              that.load_collection(widget)
          else
            that.filter_and_hightlight_elements_by_search_string(widget)
        , 600)

      doc.on 'mouseover', '.multilevel-select--li', (e) ->
        $(e.target).closest('.multilevel-select--ul').find('.multilevel-select--li.hovered').removeClass('hovered')

      doc.on 'keydown', (e) ->
        visibleCustomSelect = $('.multilevel-select .list:visible .multilevel-select--ul')

        return if visibleCustomSelect.length == 0

        keyCode = e.which
        customSelectTop = visibleCustomSelect.offset().top
        customSelectBottom = customSelectTop + visibleCustomSelect.outerHeight()

        if keyCode in [13, 38, 40] then e.preventDefault() else return true

        list = visibleCustomSelect.find('.multilevel-select--li:visible')
        selectedElement = visibleCustomSelect.find('.multilevel-select--li.hovered')

        return selectedElement.trigger('click') if keyCode == 13

        selectedElement.removeClass('hovered')

        if keyCode == 40
          nextElement = $(list[list.index(selectedElement) + 1])
          nextElement = list.first() if nextElement.length == 0
          nextElementPosition = nextElement.offset().top + nextElement.outerHeight()
        else
          nextElement = $(list[list.index(selectedElement) - 1])
          nextElement = list.last() if nextElement.length == 0
          nextElementPosition = nextElement.offset().top

        return if nextElement.is(':hidden')

        nextElement.addClass('hovered')
        if nextElementPosition < customSelectTop || nextElementPosition >= customSelectBottom
          scrollTo = visibleCustomSelect.scrollTop() + nextElement.position().top
          visibleCustomSelect.scrollTop(scrollTo - visibleCustomSelect.outerHeight() / 2)

      # RESET BUTTON
      doc.on 'click', '@new_issue_multilevel_select_reset', (e) ->
        btn = $ e.currentTarget
        return if btn.hasClass 'disabled'

        ms_id  = btn.data('custom-select-id')
        widget = btn.closest('form').find(".multilevel-select[data-custom-select-id=#{ ms_id }]")

        empty_values = {}
        widget.find('input[data-role="selected_value"]').each (index, item) ->
          input_name = $(item).data('name')
          empty_values[input_name] = ''
        empty_text = widget.find('.selected').attr('data-default')

        MultilevelSelect.set_value(widget, empty_values, empty_text)
        btn.addClass('disabled')

      true

  set_value_for: (element) ->
    widget = element.closest('.multilevel-select')
    levels = widget.attr('levels').split(' ')
    item   = element.parents('.multilevel-select--group')
    input  = widget.find("input[data-name='#{item.attr('name')}']")

    if item.length == 0 || input.length == 0
      element_input = widget.find("input[data-name='#{element.attr('name')}']")
      element_input.change().focusout()
      return

    value = item.attr('value')
    model_name = item.attr('model_name')
    input.val(value)
    input.data('model-name', model_name)

    if levels.length > 1
      @set_value_for(item)
    else
      input.change().focusout()

  set_text_and_indicator_for: (widget) ->
    parentGroup = widget.find('.multilevel-select--li.true').first().closest('.multilevel-select--group')
    selectedText = ''
    selectedIndicator = ''
    if parentGroup.length > 0
      while parentGroup.length > 0
        selectedGroup = parentGroup.children('.multilevel-select--label').find('.multilevel-select--li')
        selectedGroupTitle = selectedGroup.attr('data-text-value') || ''
        selectedGroupText = selectedGroupTitle.trim()
        selectedText = if selectedText.length > 0 then "#{selectedGroupText} \\ #{selectedText}" else selectedGroupText
        selectedIndicator ||= parentGroup.attr('indicator_params') if parentGroup.attr('indicator_params')
        parentGroup = parentGroup.parent().closest('.multilevel-select--group')
    else
      group = widget.find('.multilevel-select--group').first()
      selectedText = group.find('.multilevel-select--li').text()
      selectedIndicator = group.attr('indicator_params')

    widget.attr('selected_text', selectedText)
    widget.attr('selected_indicator', selectedIndicator)

  set_option_for: (item) ->
    widget = item.closest('.multilevel-select')

    # Reset old values
    widget.find('input').val('')

    @set_value_for(item)
    @select_option_for(widget)
    @set_text_and_indicator_for(widget)
    @set_selected_text_and_indicator_for(widget)
    widget.trigger('change')

    widget.find('.list').fadeOut(100)

    if widget.attr('simple_select')
      form = widget.closest('form')
      form.submit()

  no_results_visibility_for: (widget) ->
    no_results = widget.find('.list .no-results')

    if widget.find(".list .multilevel-select--li:visible").length is 0 && widget.attr('data-initialized')
      no_results.removeClass('hidden')
    else
      no_results.addClass('hidden')

  set_list_height_for: (widget) ->
    liHeights = 0
    ul = widget.find('.list .multilevel-select--ul')
    ul.find('.multilevel-select--li:visible').each (_index, item) ->
      liHeights += $(item).outerHeight() + 1
    spinnerHeight = ul.find('.fa-spin').first().outerHeight()
    totalHeight = liHeights + spinnerHeight

    ul.css('height': totalHeight)

  setListPositionForPreview: (widget) ->
    previewHolder = widget.closest('.preview-dialog')
    return unless previewHolder.length > 0
    leftPreviewHolderBorder = previewHolder.offset().left
    widthPreviewHolder = previewHolder.width()
    list = widget.find('.list')
    leftListBorder = list.offset().left
    rightPreviewHolderBorder = leftPreviewHolderBorder + widthPreviewHolder
    maxWidthList = 470
    rightListBorder = leftListBorder + maxWidthList
    if rightListBorder > rightPreviewHolderBorder
      list.css(right: -45)

  # needs if list does not fit in the screen
  normalize_list_position: (list) ->
    preview_holder = list.closest('.preview-dialog')
    selected = list.closest('.multilevel-select').find('.selected')
    selected_bottom = selected.offset().top + selected.outerHeight()
    list_bottom = list.offset().top + list.height()

    if list_bottom < selected_bottom
      current_list_top = list.position().top
      list.css('top', "-#{list.height()}px")
    else if preview_holder.length > 0
      preview_content = preview_holder.find('.preview-dialog__content')
      preview_top = preview_content.offset().top
      preview_bottom = preview_top + preview_content.height()
      list_top = list.offset().top
      list_bottom = list.offset().top + list.height()

      list_next_position = list_top - list.height()
      if list_next_position <= preview_top
        list.css('top', "-#{list.height() - (preview_top - list_next_position)}px")
      else if list_bottom > preview_bottom
        list.css('top', "-#{list.height()}px")
    else
      screen_height = $(window).height()
      list_height = list.height()
      list_top = list[0].getBoundingClientRect().top
      parent_offset = list.parent().offset().top
      if screen_height - list_top < list_height && parent_offset > list_height
        list.css('top', '-' + list_height + 'px')
      else
        list.css('top', '')

    screen_width = $(window).width()
    list_width = list.width()
    list_right = list[0].getBoundingClientRect().right
    if list_right > screen_width
      listHolder = list.closest('.list-holder')
      listHolder.css('left', '-' + list_width/2 + 'px')

  set_min_width_for: (widget) ->
    selected = widget.find('.selected')
    list     = widget.find('.list')

    list.css { 'min-width': selected.outerWidth() }

  show_parent_group_for: (elementGroup) ->
    parentGroup = elementGroup.closest('.multilevel-select--data').closest('.multilevel-select--group')
    return if parentGroup.length == 0

    parentGroup.removeClass('hidden')
    parentGroup.children('.multilevel-select--label').find('.multilevel-select--spoiler-btn').removeClass('closed')
    parentGroup.children('.multilevel-select--data').removeClass('hidden')

    @show_parent_group_for(parentGroup)

  select_option_for: (widget) ->
    that = @
    levels          = widget.attr('levels').split(' ')
    ul              = widget.find('.multilevel-select--ul')
    selected_holder = ul

    $.each levels, (index, level) ->
      input        = widget.find("input[data-name='#{level}']")
      selectorPart = ".multilevel-select--group[name='#{level}']"
      valuePart    = if input.val() then "[value='#{input.val()}']" else "[value=''], [value='-1']"

      if selected_holder.find(selectorPart).filter(valuePart).length > 0
        selected_holder = selected_holder.find(selectorPart).filter(valuePart)

    widget.find('.multilevel-select--li').removeClass('true')
    selectedValue = selected_holder.eq(0).children('.multilevel-select--label').find('.multilevel-select--li')

    selectedValue.addClass('true')

    if widget.attr('closed_by_default')
      groups = selectedValue.parents('.multilevel-select .multilevel-select--group:not(:first)')
      groups.each (index, group) ->
        spoilerBtn = $(group).find('.multilevel-select--spoiler-btn').first()
        spoilerContent = $(group).find('.multilevel-select--data').first()

        spoilerBtn.removeClass('closed')
        spoilerContent.removeClass('hidden')

      that.set_list_height_for(widget)

  set_selected_text_and_indicator_for: (widget) ->
    selectedText = widget.attr('selected_text') || widget.find('.selected').attr('data-default') || "&nbsp;"
    widget.find('.selected').text(selectedText).attr('title', selectedText)

    indicatorParams = widget.attr('selected_indicator') && JSON.parse(widget.attr('selected_indicator'))
    return unless indicatorParams && indicatorParams.color

    indicatorTemplate = @indicator_template(indicatorParams.color, indicatorParams.alt_text)
    widget.find('.selected').prepend(indicatorTemplate)

  set_value: (widget, values = {}, selected_text, need_callback = true, selected_indicator_params = null) ->
    widget.attr('selected_text', selected_text)
    widget.attr('selected_indicator', selected_indicator_params)
    $.each values, (key, value) ->
      widget.find("input[data-name='#{key}']").val(value).change().focusout()
    @select_option_for(widget)
    @set_selected_text_and_indicator_for(widget)
    widget.trigger('change') if need_callback

  selected_text_for: (widget) ->
    return widget.find('.selected').text()

  # HTML TEMPLATES

  indicator_template: (color, alt_text) ->
    """
    <span class='circle-color' style='background-color: #{color}' title='#{alt_text}'></span>
    """

  onchange: (select_tag, event) ->
    select_tag.on('change', event)

  offchange: (select_tag, event) ->
    select_tag.off('change', event)

`export default MultilevelSelect`

import { UPDATE_TYPE_EVENT, RESOLVE_TYPE_EVENT } from 'javascripts/react/utils/constants/issues/createForms';

const ISSUE_CREATE_FORM_WORK_TYPE_FIELD = 'issue-create-form-work-type-field';
const ISSUE_CREATE_FORM_WORK_TYPE_INPUT = 'issue-work-type-id-input';


class WorkTypeFieldInterface {
  constructor() {
    this.selectedWorkType = null;
    this.workTypeBlock = document
      .querySelector(`[data-role="${ISSUE_CREATE_FORM_WORK_TYPE_FIELD}"]`);
  }

  get isPresent() {
    return Boolean(this.workTypeBlock);
  }

  selectedTypeId() {
    if (this.selectedWorkType) { return this.selectedWorkType.id; }
    if (!this.isPresent) { return null; }

    const typeField = this.workTypeBlock.querySelector(`[data-role="${ISSUE_CREATE_FORM_WORK_TYPE_INPUT}"]`);

    return typeField && typeField.value;
  }

  subscribeToUpdates(callback) {
    if (!this.isPresent) { return; }

    $(this.workTypeBlock).on(RESOLVE_TYPE_EVENT, ({ detail }) => {
      this.selectedWorkType = detail;
      callback(detail);
    });
  }

  update(data) {
    if (!this.isPresent) { return; }

    this.selectedWorkType = { id: data.id, name: data.name };
    // eslint-disable-next-line no-undef
    const event = new CustomEvent(UPDATE_TYPE_EVENT, { detail: data });

    this.workTypeBlock.dispatchEvent(event);
  }
}

export default WorkTypeFieldInterface;

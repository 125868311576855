function isMassCreation(form: HTMLFormElement): boolean {
  return form.find('@mass-issue-creation-widget').hasClass('fa-toggle-on');
}

function disableBlock(block: JQuery<HTMLElement>): void {
  if (block.length === 0) { return; }

  block.attr('disabled', 'true').addClass('hidden');
  block.find('input, textarea, button, select').attr('disabled', 'true');
}

function enableBlock(block: JQuery<HTMLElement>): void {
  if (block.length === 0) { return; }

  block.removeAttr('disabled').removeClass('hidden');
  block.find('input, textarea, button, select').removeAttr('disabled');
}

function validateMultihiddenFields(elements: JQuery<HTMLElement>): boolean {
  const invalidElements = elements.filter('[data-valid="false"]');

  elements.removeClass('field_with_errors');
  invalidElements.addClass('field_with_errors');

  return invalidElements.length === 0;
}

export {
  isMassCreation, disableBlock, enableBlock, validateMultihiddenFields,
};

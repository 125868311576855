`import CustomSelect from 'javascripts/vendors/custom_select'`
`import setValueForCheckboxField from 'javascripts/components/shared/set_value_for_checkbox_field'`

currentInputsValues = (items, prefix) ->
  Array.from(items).reduce(((acc, item) ->
    container = $(item)
    code = container.attr('data-code')
    fieldType = container.attr('data-field')
    value = findCurrentValue(container, code, fieldType, prefix)
    acc[code] = { code: code, value: value, fieldType: fieldType } if value
    acc), new Object)

setValuesForNewInputs = (items, container, prefix) ->
  Array.from(container).forEach (item) ->
    code = $(item).attr('data-code')
    fieldType = $(item).attr('data-field')
    currentInput = items[code]
    if currentInput
      setValueForCurrentInput(item, code, fieldType, currentInput.value, prefix)

setValueForCurrentInput = (item, code, fieldType, currentInputValue, prefix) ->
  container_with_fields = $(item)
  switch fieldType
    when 'ftcheckbox'
      setValueForCheckboxField(container_with_fields, code)
    when 'ftdate'
      setValueForDateField(container_with_fields, code, currentInputValue, prefix)
    when 'ftdatetime'
      setValueForDateTimeField(container_with_fields, currentInputValue, prefix)
    when 'ftmultiselect'
      setValueForMultiSelectField(container_with_fields, code, currentInputValue, prefix)
    when 'ftselect'
      setValueForSelectField(container_with_fields, code, currentInputValue, prefix)
    when 'ftstring'
      setValueForStringField(container_with_fields, code, currentInputValue, prefix)

setValueForDateTimeField = (container, value, prefix) ->
  container.find("##{prefix}_datetime_params_date").val(value.date)
  container.find("##{prefix}_datetime_params_hour").val(value.hour)
  container.find("##{prefix}_datetime_params_minute").val(value.minute)

setValueForStringField = (container, code, value, prefix) ->
  container.find("##{prefix}_custom_parameters_#{code}").val(value)

setValueForDateField = (container, code, value, prefix) ->
  container.find("##{prefix}_custom_parameters_#{code}").val(value)

setValueForSelectField = (container, code, value, prefix) ->
  selector = container.find("##{prefix}_custom_parameters_#{code}")
  CustomSelect.set_value(selector, value)

setValueForMultiSelectField = (container, code, value, prefix) ->
  selector = container.find("##{prefix}_custom_parameters_#{code}")
  CustomSelect.set_value(selector, value)

findCurrentValue = (container, code, fieldType, prefix) ->
  if fieldType == 'ftcheckbox'
    container.find('input[type=checkbox]').prop('checked')
  else if fieldType =='ftdatetime'
    date = container.find("##{prefix}_datetime_params_date").val()
    hour = container.find("##{prefix}_datetime_params_hour").val()
    minute = container.find("##{prefix}_datetime_params_minute").val()
    { date: date, hour: hour, minute: minute }
  else
    container.find("##{prefix}_custom_parameters_#{code}").val()

`export {currentInputsValues, setValuesForNewInputs}`

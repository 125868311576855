const throttled = (func, timeout) => {
  if (window.delay_search_timer_id) {
    clearTimeout(window.delay_search_timer_id);
    window.delay_search_timer_id = null;
  }

  window.delay_search_timer_id = setTimeout(func, timeout);
};

export default throttled;

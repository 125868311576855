`import 'javascripts/vendors/jquery.datetimepicker'`
`import { InitDateTimePicker } from 'javascripts/react/services/date_time_picker_service'`

init_issue_datetime_picker = ->
  InitDateTimePicker($('@issue-datetime-picker'), (->), { pickerOptions: { minDate: 0 } })

init_general_datetime_picker = ->
  InitDateTimePicker(
    $('@datetime-picker'),
    (->),
    { pickerOptions: { timepicker: true, closeOnDateSelect: false, format: 'd/m/Y H:i'} }
  )

init_general_date_picker = ->
  InitDateTimePicker($('@date-picker'), (->))

init_filters_date_picker = ->
  InitDateTimePicker($('@filter-data-picker'), (->))

init_simple_date_and_time_masks = ->
  $(document).on 'change', '@time-picker', ->
    type = $(@).data('type')
    filtered_value = parseInt($(@).val())
    max_value = if type is 'hour' then 23 else 59

    if filtered_value < 0 || isNaN(filtered_value)
      filtered_value = '00'
    else if filtered_value > max_value
      filtered_value = max_value
    else if filtered_value < 10
      filtered_value = '0' + filtered_value

    $(@).val(filtered_value)

`export {init_issue_datetime_picker, init_general_datetime_picker, init_general_date_picker, init_simple_date_and_time_masks, init_filters_date_picker}`

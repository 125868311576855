const hideBtn = (selectors, item) => {
  const [previewRecordSelector, recordSelector] = selectors;
  if ($(`[data-${previewRecordSelector}='${$(item).data(recordSelector)}']`).length > 0) {
    $(item).hide();
  } else {
    $(item).show();
  }
};

const getPreviewRecordSelector = (item) => {
  let previewRecordSelector;
  let recordSelector;
  switch ($(item).data('preview-type')) { // eslint-disable-line default-case
    case 'company':
      previewRecordSelector = 'company-card-id';
      recordSelector = 'company-id';
      break;
    case 'agreement':
      previewRecordSelector = 'agreement-card-id';
      recordSelector = 'agreement-id';
      break;
    case 'contact':
      previewRecordSelector = 'contact-card-id';
      recordSelector = 'contact-id';
      break;
    case 'maintenance_entity':
      previewRecordSelector = 'maintenance-entity-card-id';
      recordSelector = 'maintenance-entity-id';
      break;
    case 'equipment':
      previewRecordSelector = 'equipment-card-id';
      recordSelector = 'equipment-id';
      break;
    case 'issue':
      previewRecordSelector = 'issue-card-id';
      recordSelector = 'issue-id';
      break;
  }
  return [previewRecordSelector, recordSelector];
};

const hideRepeatRecordPreviewBtn = (container) => {
  if (container) {
    container.find('.preview-btn').each((index, item) => {
      const previewRecordSelector = getPreviewRecordSelector(item);
      hideBtn(previewRecordSelector, $(item));
    });
  }
};

export default hideRepeatRecordPreviewBtn;

/* eslint-disable func-names */
import 'javascripts/vendors/jquery.datetimepicker';

function filterAllowedDates(selector, allowedDates) {
  const allowedDatesAsObjs = allowedDates.map((date) => {
    const [year, month, day] = date.split('/').map((e) => parseInt(e, 10));
    return { year, month, day };
  });

  selector
    .find('.xdsoft_date')
    .removeClass('xdsoft_other_month')
    .filter(function () {
      const year = parseInt(this.dataset.year, 10);
      const month = parseInt(this.dataset.month, 10) + 1; // +1 because indeces from 0;
      const day = parseInt(this.dataset.date, 10);

      return !allowedDatesAsObjs.some((date) => date.day === day && date.month === month
        && date.year === year);
    })
    .addClass('xdsoft_disabled');
}

function onGenerateFunc(selector, allowedDates) {
  if (allowedDates) { filterAllowedDates(selector, allowedDates); }
}

export function InitDateTimePicker(selector, changeCallback, options = {}) {
  const {
    autoShowCalendar, allowedDates, pickerOptions, inputCallback,
  } = options;

  $(selector).datetimepicker({
    onGenerate() { onGenerateFunc($(this), allowedDates); },
    lang: gon.i18n.language,
    scrollInput: false,
    timepicker: false,
    dayOfWeekStart: 1,
    closeOnDateSelect: true,
    format: 'd/m/Y',
    ...pickerOptions && { ...pickerOptions },
  }).on('change', changeCallback).on('input', inputCallback);

  if (autoShowCalendar) {
    // prevent wrong positioning bug by delay before picker showing
    setTimeout(() => $(selector).datetimepicker('show'), 100);
  }
}

export { InitDateTimePicker as default };

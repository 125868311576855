const updateCkeditorElementsOnForm = (form) => {
  if (typeof CKEDITOR === 'undefined') { return; }
  form.find('textarea').each((_, textarea) => {
    const instance = CKEDITOR.instances[$(textarea).attr('id')];
    if (!instance) { return; }
    instance.updateElement();
  });
};

export default updateCkeditorElementsOnForm;

CustomCheckbox = do ->
  init: (options) ->
    options = {} unless options
    checkbox_role = '@custom-checkbox'
    _checkbox     = '.checkbox'

    $(checkbox_role).each (i, item) ->
      holder = $ item
      fake_checkbox = holder.find(_checkbox)
      real_checkbox = holder.find('input[type=checkbox]')

      real_checkbox.data('options', options) if options

      if real_checkbox.is(':checked')
        fake_checkbox.removeClass 'false'
      else
        fake_checkbox.addClass 'false'


    @inited || = do ->
      $(document).on 'click', checkbox_role, (e) ->
        holder = $ e.currentTarget
        fake_checkbox = holder.find(_checkbox)
        real_checkbox = holder.find('input[type=checkbox]')

        if real_checkbox.is(':checked')
          real_checkbox.prop('checked', false)
          fake_checkbox.addClass 'false'
          holder.removeClass 'checked'
        else
          real_checkbox.prop('checked', true)
          fake_checkbox.removeClass 'false'
          holder.addClass 'checked'

        options['change'](real_checkbox) if options['change']
        real_checkbox.change()
        real_checkbox.data('options')?['onchange']?(holder, real_checkbox)
      true

  setValue: (checkBox, value) ->
    checkBox.find('.custom-checkbox input[type=checkbox]').
      prop('checked', value)

    checkBox.find('.checkbox').toggleClass('false', !value)
    checkBox.find('.custom-checkbox').toggleClass('checked', value)

`export default CustomCheckbox`

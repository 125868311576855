# contract 'simple interpolation'
`import escape_html from 'javascripts/components/shared/escape_html'`
`import StringUtils from 'javascripts/vendors/string_utils'`
`import IssueFormSelectBoxes from 'javascripts/components/issues/form/select_boxes'`
`import updateOnlyCalendarData from 'javascripts/components/filter/shared/update_only_calendar_data'`
`import throttled from 'javascripts/components/shared/throttled'`
`import togglePreviewBtn from 'javascripts/components/shared/toggle_preview_dialog_btn'`
`import resetInvertedFilter from 'javascripts/components/filter/reset_inverted_filter'`
`import Mark from 'mark.js'`
`import hasSearchContent from 'javascripts/react/services/search_by_words_service';`

CustomSelect = do ->
  init: (selector, options) ->
    items = $(selector)
    return false unless items.length
    options ||= {}
    options.disabled ||= false
    for item in items
      @new($(item), options) if $(item).attr('data-initialized') != 'true'

  new: (select, options) ->
    that = @
    merged_options = @set_options_for(select, options)
    @set_selected_values(select, merged_options) if select.attr('remote') is 'true'

    selected = select.closest('.custom-select').find('.selected')
    selected_text =
      if select.attr('selected_text')
        select.attr('selected_text')
      else
        @selected_text_for(select)
    selected.text(selected_text)
    selected_class = @selected_class_for(select)

    @set_class_for_selected(selected, selected_class) if selected_class
    selected.removeClass('disabled') unless merged_options.disabled

    @append_list_dropdown(select) unless select.attr('remote') is 'true'

    @initialize_events()

  set_selected_values: (select, options) ->
    selected_options = options.selected
    select.html('')

    if selected_options
      if select.attr('multiple') is 'multiple' && selected_options.length > 0
        selected_options.forEach (value) =>
          select.append(@select_option_template(value, value, true))
      else
        value = selected_options
        select.append(@select_option_template(value, value, true))
    else
      select.append(@select_option_template('', '', true))

  set_selected_value: (select, value, selected) ->
    option = select.find("option[value='#{value}']")
    if selected
      if option.length is 0
        select.append(@select_option_template(value, value, true))
    else
      option.remove()

  onclose: (select, event) ->
    opts = @get_options_for(select)
    opts.onclose = event
    @set_options_for(select, opts)

  onchange: (select, event) ->
    opts = @get_options_for(select)
    opts.change = event
    @set_options_for(select, opts)

  append_list_dropdown: (select) ->
    if select.attr('data-initialized') != 'true'
      holder   = @append_list_to_select_block(select)
      selected = holder.find('.selected')
      ul       = holder.find('.custom-select--ul')

      if $(select).attr('remote') == 'true'
        @load_collection(select)
      else
        @build_options_for(select, ul)
        holder.find('.custom-select--submit-btn').removeClass('hidden') if select.attr('multiple')
        select.attr('data-initialized', 'true')
        $(select.selector).trigger('select:data-loaded')

  append_and_show_list_dropdown: (select) ->
    @append_list_dropdown(select)
    @show_drop_down(select)

  show_drop_down: (item) ->
    holder = item.parents('.custom-select')
    select = holder.find('select')
    list   = holder.find('.list')

    if !item.attr('simple_select')
      # Set inited state
      @no_results_visibility_for select, inited=true
      @recalc_list_min_width_for select

    $('.custom-select--li.hovered').removeClass('hovered')

    if item.attr('simple_select')
      list.fadeIn()
    else
      list.show()

    @check_list_position(list)
    # set carriage at the end of Search Input
    input = list.find('input:visible')
    if input.length isnt 0
      len = input.val().length
      input.focus()[0].setSelectionRange(len, len)
    @setListPositionForPreview(holder)

  rebuild: (select) ->
    holder  = select.parents('.custom-select')
    ul      = holder.find('.custom-select--ul')

    select.attr('data-initialized', false)

    if select.attr('remote') is 'true'
      select.attr('page', 1)
      select.attr('search','')
      select.removeAttr('full-load')
    else
      holder.find('.list_holder').remove()
      @build_options_for(select, ul)

    selected = select.parents('.custom-select').find('.selected')
    selected_text =
      if select.attr('selected_text')
        select.attr('selected_text')
      else
        @selected_text_for(select)
    selected.text(selected_text)
    selected_class = @selected_class_for(select)
    @set_class_for_selected(selected, selected_class) if selected_class

    reset_btn = holder.closest('.input-with-reset').find('.reset a')
    if reset_btn.length > 0
      select_value = select.val()
      btn_disabled = select_value == null || select_value == '' || select_value == '-1' ||
                     (select.attr('multiple') is 'multiple') && select_value.join('').split('').length == 0
      reset_btn.toggleClass('disabled', btn_disabled)

      previewBtn = holder.closest('.input-with-reset').find('.preview-btn')
      if previewBtn.length > 0
        togglePreviewBtn(previewBtn, btn_disabled, select)

  load_collection: (select) ->
    that = @
    options = @get_options_for(select)
    setTimeout (-> that.show_spinner(select)), 100 unless select.attr('without_spinner')
    page = parseInt(select.attr('page')) || 1
    search = select.attr('search')
    selected =
      if select.data('with-serialized-selected-array')
        options.selected.join('-')
      else
        options.selected
    $.ajax
      url: select.attr('collection_url'),
      datatype: 'json',
      data: {
        group: select.attr('optgroup'),
        no_cache: options.no_cache,
        page: page,
        search: search,
        selected: selected if options.send_selected
      }

      success: (data) ->
        if data.option_id? && data.collection
          ul = select.closest('.custom-select').find('.list_holder .custom-select--ul')
          ul.html('') if page == 1
          that.build_options_for_remote(select, data.collection, ul)

        that.recalc_list_min_width_for select unless select.attr('simple_select')
        holder = select.parents('.custom-select')
        list   = holder.find('.list')

        select.attr('data-initialized', 'true')
        $(select.selector).trigger('select:data-loaded')
        that.init_load_more(select, data.is_last_page)
        that.no_results_visibility_for(select, true)
        setTimeout (-> that.hide_spinner(select)), 100 unless select.attr('without_spinner')
        holder.find('.custom-select--submit-btn').removeClass('hidden') if select.attr('multiple')
        if select.attr('search') && select.attr('search') != ''
          that.filter_and_hightlight_elements_by_search_string(select)

        that.check_list_position(list)
        that.initialize_scroll_event(select)

        select.removeAttr('loading-current-page')

  show_spinner: (select) ->
    widget = select.closest('.custom-select')
    spinner_wrapper = widget.find('@custom-select-spinner')
    spinner = spinner_wrapper.find('.fa-spin')
    ul = widget.find('.list_holder .custom-select--ul')
    spinner_wrapper.removeClass('hidden')

    spinner_height = spinner.height()
    spinner_width = spinner.width()
    wrapper_height = ul.outerHeight()
    wrapper_width = ul.outerWidth()
    height = if wrapper_height > 0 then wrapper_height else spinner_height
    width = if wrapper_width then wrapper_width else '100%'
    margin_left = (width - spinner_width) / 2
    margin_top = (height - spinner_height) / 2
    spinner_wrapper.css({ height: height, width: width })
    spinner.css({ 'margin-left': margin_left, 'margin-top': margin_top })

  hide_spinner: (select) ->
    select.closest('.custom-select').find('@custom-select-spinner').addClass('hidden')

  load_more: (select) ->
    return true if select.attr('full-load')

    page = parseInt(select.attr('page')) || 1
    select.attr('page', page + 1)
    @load_collection(select)

  init_load_more: (select, is_last_page = true) ->
    select.attr('full-load', true) if is_last_page

  initialize_scroll_event: (select) ->
    that = @

    select.closest('.custom-select').find('.list_holder .custom-select--ul').off('scroll').on 'scroll', (e) ->
      return if select.attr('loading-current-page')

      target = $(e.target)
      pixels_to_scroll = 50

      if (target.scrollTop() + target.height() + pixels_to_scroll) >= target.prop('scrollHeight')
        select.attr('loading-current-page', true)
        that.load_more(select)

  filter_and_hightlight_elements_by_search_string: (widget) ->
    holder = widget.parents('.custom-select')
    input = holder.find('.search input')
    list   = holder.find('.list')
    lis    = list.find('.custom-select--li')
    search_string = input.val().trim()
    text = StringUtils.escape_string(search_string)
    rexp = RegExp(text, 'mig')
    replacer = "<b>$&</b>"

    options = @get_options_for(widget)
    @hide_all_optgroups_for(holder) unless options.groups_visible_on_search

    if text.length is 0
      lis.removeClass('hidden')

      lis.each (index, li) =>
        li = $ li

        @show_optgroup_for(li)

        new_text = li.text().replace(rexp, replacer)

        li.html escape_html(new_text)
    else
      lis.addClass('hidden')

      selected = lis.each (index, li) =>
        li = $ li
        liText = li.text()
        additionalSearchText = li.data('additional-search-text')
        liText = "#{liText} #{additionalSearchText}" if additionalSearchText
        @show_and_highlight_list(li, search_string) if hasSearchContent(search_string, liText)

    @no_results_visibility_for(widget)

  show_and_highlight_list: (li, search_string) ->
    @show_optgroup_for(li)
    li.removeClass('hidden')

    markInstance = new Mark($(li).get(0))

    markInstance.unmark({
      done: ->
        markInstance.mark(search_string, {
          className: 'marked-text',
          ignoreJoiners: true,
          acrossElements: true,
        })
    })

  # SET VALUE TO CUSTOM SELECTBOX
  set_value: (selector, value, need_callback = true, selected_text = null, selected_class = null) ->
    select = $(selector)
    holder = select.closest('.custom-select')
    opts = @get_options_for(select)
    selected = holder.find('.selected')
    select.attr('selected_text', selected_text) if selected_text != null && select.attr('multiple') != 'multiple'
    select.attr('selected_class', selected_class || '')

    if select.attr('remote') == 'true'
      opts.selected = value
      @set_options_for(select, opts)
      @set_selected_values(select, opts)
      selected.text @selected_text_for(select)
      @set_class_for_selected(selected, select.attr('selected_class'))
    else
      li_items = holder.find('.custom-select--li')
      # reset selected
      opts.selected = if select.attr('multiple') is 'multiple' then [] else null
      @set_options_for(select, opts)

      # notmalize value into array
      value = [value] unless $.isArray(value)

      # set new values
      if li_items.length > 0
        for item in value
          select_item = li_items.filter("[data-value='#{ @sanitize_selected_value(item) }']")
          @set_option_for(select_item, false) if select_item.length > 0
      else
        select.val(value)
        selected.text @selected_text_for(select)
        selected_class = @selected_class_for(select)
        @set_class_for_selected(selected, selected_class)

    select.attr('data-initialized', false)

    # CHANGE EVENT CALLBACK EXEC
    if need_callback
      opts['change'](select, item) if opts['change']
      select.change().focusout()

  # OPTIONS
  set_options_for: (select, _options) ->
    # inline opts
    _noscroll = select.attr('noscroll')
    _noscroll = true if _noscroll is 'noscroll'

    _checkboxed = select.attr('checkboxed')
    _checkboxed = true if (_checkboxed is 'true') || (_checkboxed is 'checkboxed')

    _disabled = select.is(':disabled')
    _size     = parseInt (select.attr('size') || 5), 10

    # default values
    options =
      size:   _size
      search: false
      disabled: _disabled
      noscroll: _noscroll
      checkboxed: _checkboxed
      send_selected: true

    # options by priority
    if html_options = select.data('custom-select-options')
      $.extend(options, html_options) if html_options

    if data_options = select.data('options')
      $.extend(options, data_options)

    if _options
      $.extend(options, _options)

    # store options
    select.data('options', options)

    #return options
    options

  get_options_for: (select) ->
    select.data('options') || {}

  # EVENTS
  initialize_events: ->
    that = @

    @inited ||= do =>
      doc = $ document

      # ON DOCUMENT
      doc.on 'click', (e) ->
        item           = $ e.target
        msHolder       = item.parents('.custom-select')
        visibleHolders = $('.custom-select').has('.list:visible')
        nativeSelect   = msHolder.find('@custom-select, @custom-modal-select')
        closeCallback  = false

        if msHolder.length is 0 || item.hasClass('custom-select--submit-btn')
          # item is not custom-selector child
          closeCallback = true
          $('.custom-select .list').fadeOut(100)
        else if nativeSelect.attr('name').indexOf('[]') == -1
          # needs for trigger closed callback for single select
          closeCallback = true

        if visibleHolders.length > 0
          # item is custom-selector child
          # we will close all custom-selectors except this
          allLists = $('.custom-select .list:visible')
          thisList = msHolder.find('.list')
          allLists.not(thisList).hide()
          closeCallback = true if allLists.not(thisList).length > 0

          visibleHolders.each (index, visibleElement) =>
            visibleElement = $(visibleElement)
            if closeCallback && visibleElement.attr('data-value-changed') is 'true'
              select = visibleElement.find('select')
              options = that.get_options_for(select)
              options['onclose'](select) if options['onclose']
              visibleElement.attr('data-value-changed', false)

      # LIST HOLDER CLOSE EVENT
      doc.on 'close', '.list_holder', (e, select) =>
        options = @get_options_for(select)
        options['onclose'](select) if options['onclose']
        select.attr('data-value-changed', false)

      # SHOW LIST
      doc.on 'click', '.custom-select .selected', (e) =>
        target = $ e.currentTarget
        select = target.closest('.custom-select').find('select')
        return if select.hasClass('disabled')
        @append_and_show_list_dropdown(select)

      # ON OPTION CLICK
      doc.on 'click', '.custom-select .custom-select--li', (e) =>
        item   = $ e.currentTarget
        holder = item.closest('.custom-select')
        @set_option_for(item)
        holder.attr('data-value-changed', true)
        if holder.find('select').attr('simple_select')
          form = holder.closest('form')
          form.submit()

      doc.on 'click', '.custom-select .group_selectable .custom-select--optgroup', (e) =>
        groupItem = $ e.currentTarget
        holder = groupItem.closest('.custom-select')
        nextGroupItem = $(groupItem).nextAll('.custom-select--optgroup').first()
        itemSelector = '.custom-select--li:not(.hidden)'
        unselectedItemSelector = itemSelector + ":not(.true)"

        itemsInGroup = $(groupItem).nextUntil( nextGroupItem, itemSelector )
        itemsInGroupWithoutSelection = $(groupItem).nextUntil( nextGroupItem, unselectedItemSelector )
        if itemsInGroup.hasClass('true') && itemsInGroupWithoutSelection.length != 0
          itemsInGroup = $(groupItem).nextUntil( nextGroupItem, unselectedItemSelector )
        itemsInGroup.each (index, li) =>
          @set_option_for($(li))
        holder.attr('data-value-changed', true)
        if holder.find('select').attr('simple_select')
          form = holder.closest('form')
          form.submit()

      # RESET BUTTON
      doc.on 'click', '@custom_select_reset, @report_custom_select_reset, @new_issue_custom_select_reset,
                       @week-calendar-custom-select-reset,
                       @custom_select_reset_without_submit', (e) =>
        btn = $ e.currentTarget
        return if btn.hasClass 'disabled'

        ms_id  = btn.data('custom-select-id')
        select = btn.closest('form').find("select[data-custom-select-id=#{ ms_id }]")
        holder = select.parents('.custom-select')
        list   = holder.find('.list')
        options = @get_options_for(select)
        options.selected = if select.attr('multiple') is 'multiple' then [] else null
        options = @set_options_for(select, options)
        @set_selected_values(select, options) if select.attr('remote') is 'true'

        select.find('option').prop('selected', false)
        list.find('.custom-select--li').removeClass 'true'
        select.attr('selected_class', '') if select.attr('remote') is 'true'

        @set_selected_value_for(holder)
        resetInvertedFilter(select)
        btn.addClass('disabled')
        unless btn.is('@report_custom_select_reset, @new_issue_custom_select_reset, @custom_select_reset_without_submit,
                       @week-calendar-custom-select-reset')
          btn.closest('form').submit()
        if btn.is('@week-calendar-custom-select-reset')
          updateOnlyCalendarData(btn.closest('form'))
        IssueFormSelectBoxes.send_request('form_uploaded') if btn.is('@new_issue_custom_select_reset')

      # stop scrolling page if scrolling custom select
      doc.on 'mousewheel', '.custom-select--ul', (e, direction) =>
        scroll_inertia = 10
        selectbox_ul = $(e.currentTarget)
        height = selectbox_ul.height()
        scrollHeight = selectbox_ul.prop('scrollHeight') - scroll_inertia

        scrolling_bottom_maximum = (selectbox_ul.scrollTop() >= (scrollHeight - height)) && direction < 0
        scrolling_top_maximum = (selectbox_ul.scrollTop() == 0) && direction > 0

        e.preventDefault() if scrolling_bottom_maximum || scrolling_top_maximum

      doc.on 'mousewheel', '.custom-select-spinner', (e) =>
        e.preventDefault()

      # SEARCH
      doc.on 'keyup', '.custom-select .search input', (e) =>
        # do not reload collection if pressed button in KEYUP, KEYDOWN or ENTER
        return true if e.which in [13, 38, 40]

        input = $ e.currentTarget
        holder = input.closest('.custom-select')
        list = holder.find('.list')
        widget = holder.find('select')
        inputText = input.val().trim()

        throttled(->
          if widget.attr('remote') == 'true'
            widget.removeAttr('full-load')
            if inputText.length < 2
              if widget.attr('search') && widget.attr('search') != ''
                widget.attr('search', '')
                widget.attr('page', 1)
                that.load_collection(widget)
            else
              widget.attr('search', inputText)
              widget.attr('page', 1)
              that.load_collection(widget)
          else
            that.filter_and_hightlight_elements_by_search_string(widget)
            that.check_list_position(list)
        , 600)

      doc.on 'mouseover', '.custom-select--li', (e) ->
        $(e.target).closest('.custom-select--ul').find('.custom-select--li.hovered').removeClass('hovered')

      doc.on 'keydown', (e) ->
        visibleCustomSelect = $('.custom-select .list:visible .custom-select--ul')

        return if visibleCustomSelect.length == 0

        keyCode = e.which
        customSelectTop = visibleCustomSelect.offset().top
        customSelectBottom = customSelectTop + visibleCustomSelect.outerHeight()

        if keyCode in [13, 38, 40] then e.preventDefault() else return true

        list = visibleCustomSelect.find('.custom-select--li:visible')
        selectedElement = visibleCustomSelect.find('.custom-select--li.hovered')

        return selectedElement.trigger('click') if keyCode == 13

        selectedElement.removeClass('hovered')

        if keyCode == 40
          nextElement = $(list[list.index(selectedElement) + 1])
          nextElement = list.first() if nextElement.length == 0
          nextElementPosition = nextElement.offset().top + nextElement.outerHeight()
        else
          nextElement = $(list[list.index(selectedElement) - 1])
          nextElement = list.last() if nextElement.length == 0
          nextElementPosition = nextElement.offset().top

        return if nextElement.is(':hidden')

        nextElement.addClass('hovered')
        if nextElementPosition < customSelectTop || nextElementPosition >= customSelectBottom
          scrollTo = visibleCustomSelect.scrollTop() + nextElement.position().top
          visibleCustomSelect.scrollTop(scrollTo - visibleCustomSelect.outerHeight() / 2)

      true

  hide_all_optgroups_for: (holder) ->
    optgroups = holder.find('.custom-select--optgroup')
    optgroups.hide().addClass('hidden')

  show_optgroup_for: (option) ->
    if (optgroup = option.parents('.custom-select--optgroup')).length
      optgroup.show().removeClass('hidden')

  recalc_list_min_width_for: (select) ->
    holder   = select.parents('.custom-select')
    selected = holder.find('.selected')
    list     = holder.find('.list')

    list.css { 'min-width': selected.outerWidth() }

  # needs if list does not fit in the screen
  check_list_position: (list) ->
    list_height = list.height()
    list_bottom = list.offset().top + list_height
    selected = list.closest('.custom-select').find('.selected')
    selected_bottom = selected.offset().top + selected.outerHeight()
    preview_holder = list.closest('.preview-dialog')
    permissions_table = list.closest('.permissions-table-wrapper')

    if list_bottom < selected_bottom
      current_list_top = list.position().top
      list.css('top', "#{selected_bottom - list_bottom + current_list_top}px")
    else if preview_holder.length > 0
      preview_content = preview_holder.find('.preview-dialog__content')
      preview_top = preview_content.offset().top
      preview_bottom = preview_top + preview_content.height()
      list_next_position = list.offset().top - list.height() - list.position().top
      if list_next_position <= preview_top
        list.css('top', "-#{list.height() - (preview_top - list_next_position)}px")
      else if list_bottom > preview_bottom
        list.css('top', '-' + list_height + 'px')
    else if permissions_table.length > 0
      scrollable_columns = permissions_table.find('.scrollable-columns')
      scrollable_columns_bottom = scrollable_columns.offset().top + scrollable_columns.height()
      scrollable_columns_right = scrollable_columns.offset().left + scrollable_columns.width()
      list_width = list.width()
      list_right = list.offset().left + list_width

      if list_bottom > scrollable_columns_bottom
        list.css('top', '-' + list_height + 'px')

      if list_right > (scrollable_columns_right - list_width)
        list.css('left', "-#{list_width/2}px")
    else
      screen_height = $(window).height()

      if list.closest('.settings-table').length > 0
        list.css('top', '')
        scrollbar_container_height = $('.main-box .mCSB_container').innerHeight()
        if screen_height > scrollbar_container_height
          screen_height = scrollbar_container_height
        else
          headerHeight = $('header').innerHeight()
          footerHeight = $('footer').innerHeight()
          breadcrumbsHeight = $('.main-breadcrumbs').innerHeight()
          screen_height = screen_height - headerHeight - footerHeight - breadcrumbsHeight

      list_bottom_on_screen = list[0].getBoundingClientRect().top + list_height
      if list_bottom_on_screen > screen_height
        list.css('top', '-' + list_height + 'px')
      else
        list.css('top', '')

    screen_width = $(window).width()
    list_width = list.width()
    list_right = list[0].getBoundingClientRect().right
    if list_right > screen_width
      list.css('left', '-' + list_width/2 + 'px')


  setListPositionForPreview: (widget) ->
    previewHolder = widget.closest('.preview-dialog')
    return unless previewHolder.length > 0
    leftPreviewHolderBorder = previewHolder.offset().left
    widthPreviewHolder = previewHolder.width()
    leftListBorder = widget.offset().left
    rightPreviewHolderBorder = leftPreviewHolderBorder + widthPreviewHolder
    maxWidthList = 470
    rightListBorder = leftListBorder + maxWidthList
    if rightListBorder > rightPreviewHolderBorder
      widget.find('.list').css(right: -25)

  # option `inited`
  # on first show of list we haven't worry about visibility of options
  #
  no_results_visibility_for: (select, inited = false) ->
    holder = select.parents('.custom-select')
    no_results = holder.find('.list .no-results')
    hiddens    = holder.find('.list .custom-select--li.hidden')

    visibilty = if inited then '' else ':visible'

    if holder.find(".list .custom-select--li#{ visibilty }").not(hiddens).length is 0
      no_results.show().removeClass('hidden')
    else
      no_results.hide().addClass('hidden')

  set_option_for: (item, need_callback = true) ->
    holder   = item.parents('.custom-select')
    select   = holder.find('select')
    opts     = @get_options_for(select)

    if select.attr('multiple') is 'multiple'
      @set_multiple_selected_value_for(item)
    else
      @set_single_selected_value_for(item)

    # CHANGE EVENT CALLBACK EXEC
    if need_callback
      opts['change'](select, item) if opts['change']
      select.change().focusout()

  set_selected_value_for: (holder) ->
    selected = holder.find('.selected')
    select   = holder.find('select')
    selected.text @selected_text_for(select)
    @set_class_for_selected(selected) if select.attr('remote') is 'true'

  selected_text_for: (select) ->
    selected_text = if select.attr('multiple') is 'multiple'
      @text_for_multiple_selected_for(select)
    else
      @text_for_single_selected_for(select)

  selected_class_for: (select) ->
    if select.attr('selected_class')
      select.attr('selected_class')
    else
      selected = select.find('option:selected')
      selected.attr('class') if selected.length

  # MULTIPLE
  set_multiple_selected_value_for: (item) ->
    holder   = item.parents('.custom-select')
    selected = holder.find('.selected')
    select   = holder.find('select')

    k = item.text()
    v = item.data('value')

    selectedItems = $(holder[0].querySelectorAll(".custom-select--li[data-value='#{ @sanitize_selected_value(v) }']"))
    selectedItems.each (index, li) =>
      $(li).toggleClass('true')

    state = item.hasClass('true')

    # Use querySelector for fix error https://tasks.okdesktech.com/issues/9636
    $(select[0].querySelectorAll("option[value='#{ @sanitize_selected_value(v) }']")).prop('selected', state)
    @set_multiple_selected_to_options(select, item, state, v)
    @set_selected_value(select, v, state) if select.attr('remote') is 'true'
    selected.text @selected_text_for(select)

  text_for_multiple_selected_for: (select) ->
    options = @get_options_for(select)
    count = options.selected && options.selected.length || 0

    if count is 0
      @locale(select, 'not_set')
    else
      @locale(select, 'selected').interpolate { count: count }

  # SINGLE
  set_single_selected_value_for: (item) ->
    holder   = item.parents('.custom-select')
    selected = holder.find('.selected')
    select   = holder.find('select')

    item.addClass('true')

    # get current state
    k = item.attr('title')
    v = item.data('value')
    selected_class = item.data('class') || ''
    state = item.hasClass('true')

    # reset values
    holder.find('.list .custom-select--li').removeClass('true')
    select.find('option').prop("selected", false)

    # set select value
    # Use querySelector for fix error https://tasks.okdesktech.com/issues/9636
    $(select[0].querySelectorAll("option[value='#{ @sanitize_selected_value(v) }']")).prop("selected", state)
    @set_single_selected_to_options(select, item, state, v)
    if select.attr('remote') is 'true'
      select.html('')
      @set_selected_value(select, v, state)
      select.attr('selected_text', k)
      select.attr('selected_class', selected_class)
    @set_class_for_selected(selected, @selected_class_for(select))
    item.addClass('true') if state

    selected.text @selected_text_for(select)
    holder.find('.list').fadeOut(100)

  set_class_for_selected: (selected, selected_class = null) ->
    # remove previous indication class from selected value
    selected.attr('class', 'selected')
    selected.addClass selected_class

  set_multiple_selected_to_options: (select, item, state, value, single = false) ->
    options = @get_options_for(select)
    options.selected = [] unless options.selected
    index = options.selected.indexOf(value)
    index = options.selected.indexOf(value.toString()) if index < 0
    if state && index < 0
      options.selected.push(value)
    else
      options.selected.splice(index, 1)
    @set_options_for(select, options)

  set_single_selected_to_options: (select, item, state, value, single = false) ->
    options = @get_options_for(select)
    changed = `options.selected != value`
    if changed
      options.selected = value
      @set_options_for(select, options)

  text_for_single_selected_for: (select) ->
    selected_text =
      if select.attr('remote') is 'true'
        select.attr('selected_text')
      else
        selected = select.find('option:selected')
        selected.text() if selected.length

    selected_text || @locale(select, 'not_set')

  sanitize_selected_value: (val) ->
    val.toString().replace(/\\/g, '\\\\').replace(/'/g, "\\'")

  # RENDERING
  build_options_for: (select, node) ->
    select_items = select.find('> optgroup, > option')

    if select.has('optgroup').length > 0
      for optgroup in select_items
        optgroup = $ optgroup
        options  = optgroup.find('option')

        label = optgroup.attr('label')
        opt = @optgroup_template(label)
        node.get(0).appendChild(opt) if options.length > 0
        @options_to_html(options, opt)
    else
      @options_to_html(select_items, node)


  build_options_for_remote: (select, collection, node) ->
    selected_values = @get_selected_values(select)

    if select.attr('optgroup') is 'true'
      for _index, group of collection
        [label, opt_collection] = if $.isArray(group) then group else [group.name, group.items]

        opt = @optgroup_template(label)
        node.get(0).appendChild(opt) if opt_collection.length > 0
        @remote_collection_to_html(opt_collection, selected_values, node)
    else
      @remote_collection_to_html(collection, selected_values, node)
    node.get(0).appendChild(@no_results_template(@locale(select, 'no_results'))) != undefined

  remote_collection_to_html: (collection, selected_values, node) ->
    for item in collection
      [text, value, optionIndicationClass, subtitle, additionalSearchText, altText] =
        if $.isArray(item) then item else [item.title, item.id, item.class_name,
                                           item.subtitle, item.additional_search_text,
                                           item.alt_text]

      is_selected = selected_values && (selected_values.indexOf(value.toString()) >= 0)
      @append_child_to_node(node, text, value, is_selected, optionIndicationClass,
                            subtitle, additionalSearchText, altText)

  get_selected_values: (select) ->
    options = @get_options_for(select)
    selected_values = options.selected
    if selected_values
      selected_values = [selected_values.toString()] unless selected_values instanceof Array
      for k, v of selected_values
        selected_values[k] = v.toString()

    selected_values

  options_to_html: (selectItems, node) ->

    for selectItem in selectItems
      selectItem = $ selectItem

      k = selectItem.text()
      v = selectItem.val()

      selectItemElement = selectItem[0]

      is_selected = selectItemElement.selected
      item_class = selectItemElement.className
      subtitle = $(selectItemElement).data()['subtitle']

      @append_child_to_node(node, k, v, is_selected, item_class, subtitle)

  # LOCALE
  locale: (select, key)  ->
    locale = select.data('locale') || {}
    return text if text = locale[key]
    ''

  # HTML TEMPLATES

  # .custom-select
  #   div(style='display:none')
  #   .selected= 0
  #   .list_holder
  #     .list
  #       .title Some title
  #       .search
  #         input(type=text)
  #       ul
  #         li= 10
  #         li= 20
  #         li= 30
  #         li= 40
  append_child_to_node: (node, text, val, select, optionIndicationClass = null,
                         subtitle = null, additionalSearchText = null, altText = null) ->
    if node instanceof Node
      node.children[1].appendChild(@option_template(text, val, select, optionIndicationClass, subtitle)) if node.children[1] != undefined
    else if node.get(0) != undefined
      node.get(0).appendChild(
        @option_template(text, val, select, optionIndicationClass, subtitle, additionalSearchText, altText)
        )

  append_list_to_select_block: (select) ->
    opts         = @get_options_for(select)
    select_block = select.parents('.custom-select')

    title       = @locale(select, 'title')
    placeholder = @locale(select, 'placeholder')
    not_set     = @locale(select, 'not_set')

    with_search      = if opts['search']           then 'with-search'      else ''
    noscroll         = if opts['noscroll']         then 'noscroll'         else ''
    checkboxed       = if opts['checkboxed']       then 'checkboxed'       else ''
    right_aligned    = if opts['right_aligned']    then 'right-aligned'    else ''
    with_line_breaks = if opts['with_line_breaks'] then 'with-line-breaks' else ''
    simple_select    = if opts['simple_select']    then 'simple-select'    else ''
    group_selectable = if opts['group_selectable'] then 'group_selectable' else ''
    pagination_info_text = select.attr('data-pagination-info-text')
    load_more_text = select.attr('data-load-more-text')
    submit_btn_class =  if select.attr('multiple') && !select.attr('remote') == 'true' then '' else 'hidden'

    select_block.find('.list_holder').remove() # remove holder if already exists
    select_block.append """
      <div class='list_holder' style='z-index:3'>
        <div class='list #{ with_search } #{ checkboxed } #{ right_aligned } #{ with_line_breaks } #{ simple_select } #{group_selectable}'>
          <div class='title'>#{ escape_html(title) }</div>
          <div class='search'><input type='text' placeholder='#{ placeholder }'></div>
          <div class='custom-select-spinner hidden' data-role='custom-select-spinner'>
            <i class='fa fa-spin fa-refresh fa-2x'></i>
          </div>
          <div class='custom-select--ul #{ noscroll }'>#{ @no_options_template(not_set) }</div>
          <div class='custom-select--submit-btn submit-btn submit-btn-with-ok #{ submit_btn_class }'>
            #{ @locale(select, 'submit_btn') }
          </div>
        </div>
      </div>
    """

    # return root item
    return select_block

  optgroup_template: (label, optgroup_html) ->
    optgroup_div = document.createElement('div')
    optgroup_div.className = 'custom-select--optgroup'
    label_optgroup = document.createElement('div')
    label_optgroup.className = 'custom-select--optgroup-label'
    label_optgroup.textContent = label
    optgroup_div.appendChild(label_optgroup)
    optgroup_options = document.createElement('div')
    optgroup_options.className = 'custom-select--optgroup-options'
    optgroup_div.appendChild(optgroup_options)
    optgroup_div


  option_template: (text, value, selected, optionIndicationClass = null,
                    subtitle = null, additionalSearchText = null, altText = null) ->
    div = document.createElement('div')
    className = if optionIndicationClass then "custom-select--li #{optionIndicationClass}" else 'custom-select--li'
    div.className = if selected then "#{className} #{selected}" else className
    title = altText? && altText || text
    div.setAttribute('title', title)
    div.setAttribute('data-value', value)
    div.setAttribute('data-class', optionIndicationClass) if optionIndicationClass
    div.setAttribute('data-subtitle', subtitle) if subtitle
    div.setAttribute('data-additional-search-text', additionalSearchText) if additionalSearchText
    div.textContent = text
    div.appendChild(@subtitle_template(subtitle)) if subtitle
    div

  subtitle_template: (subtitle) ->
    div = document.createElement('div')
    div.setAttribute('title', subtitle)
    div.textContent = subtitle
    div.className = 'subtitle'
    div

  no_results_template: (text) ->
    div = document.createElement('div')
    div.className = 'no-results'
    div.setAttribute('data-role', 'no-results')
    div.textContent = text
    div

  no_options_template: (text) ->
    """
    <div data-role='no-results' class='no-results'>#{ text }</div>
    """

  select_option_template: (text, value, selected) ->
    """
    <option value='#{ value }' #{ 'selected="selected"' if selected }>#{ text }</option>
    """

`export default CustomSelect`

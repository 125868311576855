import URLSearchParams from '@ungap/url-search-params';

const showPreviewBtn = btn => btn.closest('.preview-btn').css('visibility', 'visible');

const appendDynamicParamsForMultilivelSelectAttributes = (btn, select, params) => {
  const companyId = select.find('input#issue_company_id').val();
  const contactId = select.find('input#issue_contact_id').val();
  if (companyId !== '' && companyId !== null) {
    btn.attr('href',
      `/companies/${companyId}/previews?${params.toString()}`);
    btn.attr('data-company-id', companyId);
    btn.attr('data-preview-type', 'company');
    showPreviewBtn(btn);
    select.closest('.input-with-reset').find('.preview-dialog').attr('data-company-id', companyId);
  } else if (contactId !== '' && contactId !== null) {
    btn.attr('href',
      `/contacts/${contactId}/previews?${params.toString()}`);
    btn.attr('data-contact-id', contactId);
    btn.attr('data-preview-type', 'contact');
    showPreviewBtn(btn);
    select.closest('.input-with-reset').find('.preview-dialog').attr('data-contact-id', contactId);
  }
};


const appendDynamicParamsForCustomSelectAttributes = (btn, select, params) => {
  if (select.val() === '' || select.val() === null) { return null; }
  btn.attr('href',
    `${select.data('preview-path')}/${select.val()}/previews?${params.toString()}`);
  btn.attr(`data-${select.data('attribute-name')}`, select.val());
  showPreviewBtn(btn);
  select.closest('.input-with-reset')
    .find('.preview-dialog')
    .attr(`data-${select.data('attribute-name')}`, select.val());
  return null;
};

const appendDynamicParams = (btn, select) => {
  const params = new URLSearchParams();
  params.append('find_by_real_id', true);
  params.append('preview_dialog_id', btn.data('preview-dialog-id'));
  params.append('preview_on_issue_form', true);

  if (select.hasClass('multilevel-select')) {
    appendDynamicParamsForMultilivelSelectAttributes(btn, select, params);
  } else {
    appendDynamicParamsForCustomSelectAttributes(btn, select, params);
  }
};

const toggleVisibility = (btn, state) => {
  if (state) {
    btn.css('visibility', 'hidden');
  } else {
    btn.css('visibility', 'visibile');
  }
};

const togglePreviewBtn = (previewBtn, btnState, select) => {
  const contactModelName = select.find('input#issue_contact_id').data('model-name');
  const companyId = select.find('input#issue_company_id').data('model-name');
  if ((companyId === '' || companyId === null) || contactModelName === 'employees') { return null; }
  toggleVisibility(previewBtn, btnState);
  appendDynamicParams(previewBtn, select);
  return null;
};

export default togglePreviewBtn;

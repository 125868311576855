update_url_parameter = (url, param, param_val) ->
  new_additional_url = ''
  temp_array = url.split('?')
  base_url = temp_array[0]
  additional_url = temp_array[1]
  temp = '';

  if additional_url
    temp_array = additional_url.split('&')
    for item in temp_array
      if item.split('=')[0] != param
        new_additional_url += temp + item
        temp = '&'

  rows_txt = temp + "" + param + "=" + param_val
  return base_url + "?" + new_additional_url + rows_txt

`export default update_url_parameter`

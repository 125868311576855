import RegexpService from 'javascripts/react/services/regexp_service';

export default function hasSearchContent(searchString, text) {
  const searchWords = searchString.trim().split(/\s+/);
  const hasMissingSearchWordsInText = searchWords.some((word) => {
    const escapedWord = new RegexpService(word).escapeString();
    const regExp = RegExp(escapedWord, 'mig');
    return !text.match(regExp);
  });
  return !hasMissingSearchWordsInText;
}
